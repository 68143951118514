.project-title {
  margin-bottom: 24px;

  h4 {
    font-size: 4rem;

    &.delete-h4 {
      text-align: center;
    }
  }

  span {
    font-size: 1.7rem;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);
  }

  #confirm-delete {
    font-size: 2.5rem;
    color: #fc7d7d;
    font-weight: bold;
  }

  .element-centred {
    margin-top: 2rem;
    justify-content: center;
  }
}

.objective-wrapper {
  display: flex;
  text-align: center;
  align-items: baseline;
  margin-left: -10%;
  gap: 1%;

  @media screen and (max-width: 1480px) {
    margin-left: -2%;
  }
}