.card-big-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;

  @media screen and (max-width: 1039px) {
    flex-direction: column-reverse;
  }

  span {
    @media screen and (max-width: 1039px) {
      margin-bottom: 2%;
      font-weight: bold;
      font-size: 1.5rem;
      margin-left: 5%;
    }
  }
}

.card {
  position: relative;
  margin: 13px 0;
  height: 7rem;
  width: 100%;
  border-radius: 53px;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.16);
  background: #a9efe5;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 2rem;
  justify-content: space-between;

  .small-card {

    height: 7rem;
    border-radius: 53px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    display: flex;
    align-items: center;
    padding-left: 2.7rem;

    span {
      font-size: 2.4rem;
      font-weight: bold;

      @media screen and (max-width: 747px) {
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 747px) {
      width: 48%;
    }

    @media screen and (max-width: 1039px) {
      width: 100%;
    }
  }

  .small-card-overridden {
    right: 0;
    width: 60%;

    p {
      color: #808786;
      font-size: 1.4rem;
      margin-left: 2%;
    }

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  .card-buttons {
    // position: absolute;
    display: flex;
    // left: 65%;
    // left: 53%;
    // top: 15%;
    gap: 2.3rem;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;

    // @media screen and (max-width: 1700px) {
    //   left: 46%;
    // }

    // @media screen and (max-width: 747px) {
    //   left: 61%;
    // }

    // @media screen and (max-width: 618px) {
    //   left: 55%;
    // }

    // @media screen and (max-width: 1550px) {
    //   // left: 53%;
    //   left: 45%;
    // }

    @media screen and (max-width: 524px) {
      left: 52%;
      gap: 1.5rem;
    }

    @media screen and (max-width: 1039px) {
      left: 50%;
      gap: 1.2rem;
    }
  }

  .card-button-objectf {
    left: 45%;
  }

  .Buttons {
    width: 130px;
    height: 4.6rem;
    font-weight: 400;
    border-radius: 40px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #ffffff;
    color: rgba(111, 111, 111, 0.87);
    font-size: 17px;

    @media screen and (max-width: 1200px) {
      width: 57px;
    }

    span {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    @media screen and (max-width: 1039px) {
      display: none;
    }
  }
}

// .card-overridden {
//   background: #fc7d7d;
//   margin-bottom: 7.4rem;
// }