@import "./Auth/container";
@import "./Project/ProjectCreate";
@import "./Project/ProjectList";
@import "./Project/ProjectDelete";
@import "./Graph/graph";

.element-centred {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-button {
  width: 194px !important;
  height: 41px !important;
  font-size: 2.1rem !important;
  font-weight: bold !important;
}