.project-container {
  @media screen and (max-width: 772px) {
    margin: 4.2rem 3rem;
  }

  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  .new-project-big-container {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #f0fcfa;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px 60px 3.7rem 8rem;
    width: 80%;

    &.delete-project {
      width: 70%;
    }

    @media screen and (max-width: 1480px) {
      width: 95%;
      padding: 3rem;
    }

    .project-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        font-size: 1.7rem;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.87);
        margin-left: 1.2rem;
      }

      img {
        width: 280px;
        margin-bottom: 26px;

        @media (max-width: 1690px) {
          width: 210px;
          margin-bottom: 15px;
        }

        @media screen and (max-width: 736px) {
          width: 250px;
        }
      }
    }
  }
}
