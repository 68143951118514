.sidebar-closed {
  width: 100px;
  align-items: center !important;

  .divider {
    width: 52px !important;
    margin-left: 7px !important;
  }

  .treso-divider {
    border: 0.5px solid #f0f0f0;
  }

  .button {
    padding: 0px;
  }

  .sidebar_header {
    margin-bottom: -32px;
    padding-bottom: 80px !important;
  }

  .input-label-range-picker {
    display: none;
  }

  #range-id {
    margin-left: -10px;
    height: 30px;
    width: 30px;
  }

  .hidden-element {
    display: none;
  }

  .ant-btn>span {
    display: none !important;
  }

  .border-category {
    width: 100% !important;
    height: 40px;
    min-height: 40px;
    max-height: 40px;

    .nav-img {
      width: 35px;
      height: 35px;
    }
  }

  #arrow {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }

  .mode-button:hover {
    background-color: transparent !important;
    box-shadow: none;
  }

  .mode-button {
    transform: rotate(-90deg);
    margin-bottom: 45px;
    background-color: inherit;
    box-shadow: none;
    width: auto;

    .nav-img {
      display: none;
    }

    .hidden-element {
      display: none;
    }

    .button-group {
      background-color: $white;
      border-radius: 26px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      transform-origin: left top;
      justify-content: center;
    }

    img {
      transform: rotate(90deg);
    }

    & .switch-text {
      display: none;
    }

    .comptability-container {
      padding-right: 35px;
      display: flex;
      justify-content: flex-end;
    }

    .tresory-container {
      margin-right: -45px;
      margin-top: 6px;
    }
  }





  .button {
    justify-content: center;

    &:hover {
      text-shadow: 0px 0px 0.5px $guyabano;
      background: $guyabano;
    }

    &.config-buttons {
      gap: 0;
      max-height: 40px;
      min-height: 40px;
    }

    .text-container {
      display: none;
    }

    &#arrow {
      display: none;
    }

    &.nav-link {
      width: 100%;
      border: none;
      gap: 18px;
      max-height: 40px;
      min-height: 40px;

      >.nav-img:first-of-type {
        width: 30px;
        height: 30px;
      }

      &:nth-child(n + 6):nth-child(-n + 10) {
        gap: 0;

        &.nav-img {
          margin-right: 0;
        }
      }
    }
  }

  .setting-wrapper {
    .element-centred {
      justify-content: space-between;
    }
  }

  .border-category {
    width: 100%;
  }
}

.ant-picker-suffix {
  img {
    height: 30px !important;
  }
}

///switch

.mode-button {
  @media only screen and (max-width: 900px) {
    width: auto !important;
    background-color: inherit !important;
    transform: rotate(-90deg);
    margin-bottom: 45px !important;
    box-shadow: unset !important;

    .nav-img {
      display: none;
    }

    .hidden-element {
      display: none;
    }

    .button-group {
      background-color: $white;
      border-radius: 26px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      transform-origin: left top;
      justify-content: center;
    }

    img {
      transform: rotate(90deg);
    }

    & .switch-text {
      display: none;
    }

    .comptability-container {
      padding-right: 35px;
      display: flex;
      justify-content: flex-end;
    }

    .tresory-container {
      margin-right: -45px;
      margin-top: 6px;
    }
  }
}

.button {
  @media only screen and (max-width: 900px) {
    width: auto;

    .text-container {
      display: none;
    }
  }
}