.table-scroll {
    position: relative;
    z-index: 1;
    margin: auto;
    overflow: auto;



}

.table-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.table-wrap {
    position: relative;
}

.table-scroll tbody th,
.table-scroll tbody td {
    border: 0.5px solid rgb(235, 235, 235);
    vertical-align: top;
}

.table-scroll thead {
    height: 49px;
}





.table-scroll thead th {
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
    top: 0;
}

.table-scroll tbody tr {
    display: flex;
}



.table-scroll th:first-child,
.table-scroll td:first-child {
    position: -webkit-sticky;
    position: sticky;
    height: 38px;
    width: 175px;
    left: 0;
    z-index: 6;
}

.table-scroll tbody th {
    position: -webkit-sticky;
    position: sticky;
    height: 38px;
    width: 210px;
    z-index: 6;
}

// .ant-popover-inner:has(.add-element-2),
.ant-popover-inner:has(.modify-delete),
.ant-popover-inner:has(.add-edit-popover) {
    padding: 0px !important;
}


.delete-confirm-wrapper {
    min-width: 100vw;
    min-height: 100vh;
    z-index: 100;
    background-color: #ffffff52;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .Overlay-delete-confirm {
        min-width: 100vw;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
    }

    .delete-confirm-container {
        z-index: 102;
        width: 58%;
        text-align: center;
        border-radius: 40px;
        background: #f0fcfa;
        padding: 50px 60px 3.7rem 8rem;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);

        h4 {
            color: #000;

            font-size: 40px;
            font-style: normal;
            // line-height: 68px;
        }

        .delete-button-containers {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14%;
            margin-top: 2rem;

            .action-button {
                font-size: 22px;
                font-weight: bold;

                height: 41px;
                width: 194px;
            }
        }

        .are-you-sure {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 11px;

            p {
                color: #fc7d7d;
                font-size: 25px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: -0.375px;
            }
        }
    }
}