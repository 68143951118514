.table-buttons {
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 20;
  gap: 25px;
  top: 50%;
  left: 91%;
  transform: translateY(calc(-50%));

  @media screen and (max-width: 1384px) {
    left: 88%;
  }

  @media screen and (max-width: 1131px) {
    left: 85%;
  }

  h3 {
    font-size: 1.4rem;
    margin-bottom: 19px;
  }

  #select-all {
    font-size: 1.3rem;
  }

  .small-popover-flowing {
    right: 400px;
    background-color: #ffffff;

    padding: 25px 30px;

    &.filters-for-status {
      right: 387px;
      top: 63%;

      &.filters-for-status-listing {
        top: 50%;
      }


    }

    &.sorting-popover {
      transform: translate(-62%, 19%);
      right: 0;

    }

    .small-popover-wrapper {
      width: 100%;
      padding: 0rem;

      .arrow-popover {
        border: none;
      }

      .sort-popup {
        #sort-title {
          text-align: center;
          font-weight: 500;
        }

        .sort-options {
          form {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .option {
              padding: 4px 0;

              label {
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 15px;
                cursor: pointer;

                input[type="radio"] {
                  font-size: 3em;
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                  border: 1.5px solid #707070;
                  background-clip: border-box;
                  border-radius: 50%;
                  appearance: none;
                  transition:
                    background-color 0.3s,
                    box-shadow 0.3s;
                }

                input[type="radio"]:checked {
                  box-shadow: 0px 0px 0px 1px #707070;
                  background-color: #707070;
                  border-color: #ffffff;
                }

                span {
                  display: flex;
                  // background-color: rgb(255, 255, 255);
                  // box-shadow: 0px 5px 5px 0px rgba(240, 240, 240, 0.75);
                  // padding: 5px 10px;
                  width: 100%;

                  p {
                    white-space: nowrap;
                  }
                }

                img {
                  width: 15px;
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .change-type {
    margin-bottom: 10px;

    .ant-radio-inner {
      background-color: white;
      border-color: grey;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: grey;
        border: 1px solid white;
      }
    }

    .elements-types {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 1rem;
      border-radius: 40px;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
      padding: 2px 10px;
      font-weight: 600;
      width: 120px;
    }
  }

  .filter-icon,
  .sort-icon {
    position: absolute;
    width: 38px;
    height: 38px;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50%;
    left: 80%;
    z-index: 50;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    img {
      width: 15.5px;
      height: 20.8px;
    }
  }

  .sort-icon {
    bottom: 50%;
  }
}

.float-buttons {



  .filter-buttons {
    width: 125px;
    height: 42px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    font-size: 1.6rem;
    font-weight: 400;

    &.button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 17px;
      gap: 5px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .sort-both-icon {
      width: 3rem;
    }
  }
}

.filter-button {
  width: 150px;
  height: 30px;
  margin-top: 12px;
}

.ant-checkbox-inner {
  border: 0;
  border-radius: 20px;
}

.table-buttons {
  z-index: 2000;

  .float-buttons {
    position: relative;

    .filter-buttons {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.table-buttons {
  .float-buttons-white-icon {
    .filter-buttons {
      img {
        filter: brightness(1) invert(0);
      }
    }
  }
}


.popover-regroupment {
  padding: 0;
  text-align: center;
  transform: translate(calc(103% + 10px), -60%) !important;

  &.popover-regroupment-listing {
    transform: translate(calc(103% + 10px), -71%) !important;
  }


  .left-radios {
    text-align: left;
  }

  &.small-popover-flowing {
    padding: 1.5rem;
  }

  h3 {
    margin: 0 auto 10px auto;
    text-align: center;
    font-size: 2rem;
  }

  margin: 0;

  .radio-regroupment {
    margin-bottom: 13px;
  }

  .label-regroupment {
    white-space: nowrap;
  }
}

.arrows {
  top: 50%;
  width: 0;
  height: 0;
  fill: #f0fcfa;
  position: absolute;
  right: 0;
  transform: translate(100%, -50%);
  border-left: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
}

.writing-float-button {
  h3 {
    white-space: nowrap;
  }

  .button-add-element {
    width: 108px;
    height: 45px;
    border-radius: 40px;
    margin-top: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    color: grey;
    background: #ffffff;
  }
}


.popover-writing-float-button {
  .small-popover-wrapper .arrow-popover {
    right: -30px !important;
    position: absolute;
  }
}