.graph {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-left: 1%;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .chart-buttons {
    top: 0%;
    position: fixed;
    left: 50%;
    transform: translateY(calc(50%));
    font-weight: bold;
    
    @media only screen and (max-width: 1293px) {
      left: 33%;
    }
    @media only screen and (max-width: 1024px) {
      left: 25%;
      top: 4%;
    }
      .arrow {
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid transparent;
          top:-20px;
          border-bottom: 10px solid rgb(255, 255, 255);
          position: absolute;
          right:250px;
      }

      .compta-style {
        left: 162%;
      }

      .treso-style {
        left: 15%;
      }

      .element-treso-style {
        left: 1%;
      }

      .element-compta-style {
        left: 148%;
        position: absolute;
      }
   
  }

  .chart-elements-types {
    display: flex;
    align-items: center;
    gap: 4px;
    min-width: 28.2rem;
    margin-top: 1.2rem;
    border-radius: 40px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    padding: 6px 10px;
    font-weight: 600;

    & > div {
      white-space: nowrap;

      &.chart-small-signature {
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    gap: 2px;
    align-items: stretch;
    flex: 1;
  }
  .elements-styled {
    position: relative;
  }

}
