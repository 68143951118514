@import "./assets/scss/utilities/fonts";
@import "./assets/scss/utilities/mixins";
@import "./assets/scss/utilities/variables";
@import "./assets/scss/utilities/base";
/* ----------------------------------- */
@import "./layouts/Dashboard/dashboard";
@import "./layouts/SideBar/sideBar";
@import "./layouts/SideBar/sideBarRespensive";
@import "./pages/pages";
@import "./components/components";

html,
body {
  height: 100%;
  scrollbar-width: thin;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.rtl {
  direction: rtl;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Pangram Regular';
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  min-height: 100%;
  height: 100%;

  @media (max-width: 1550px) {
    font-size: 60%;
  }

  @media (max-width: 1350px) {
    font-size: 57%;
  }

  @include scroll;
}

.main {
  margin: 2rem 6rem;
}

#root {
  min-height: 100vh;
  height: 100%;

  &>.wrapper {
    min-height: 100vh;
    height: 100%;

    &>.ant-layout {
      min-height: 100vh;
      height: 100%;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(212, 212, 212);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 1px;
}

::-webkit-scrollbar:hover {
  width: 3px;
  height: 10px;
}

// ::-webkit-scrollbar-thumb:hover {
//   background: #640909;
//   width: 4px;
//   height: 10px;
// }

.ant-popover-inner {
  border-radius: 30px !important;
}