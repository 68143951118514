.wrapper-fullpage {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent radial-gradient(closest-side at 50% 50%, #D3FFF9 0%, #A9EFE5 100%) 0% 0% no-repeat padding-box;
    padding: 7rem 5rem;

    .logo-desc-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3.8rem;
        width: 27rem;

        .logo-login {
            width: 23rem;
        }

        .logo-login>img {
            width: 23rem;
        }
    }

    p {
        font-size: 2.5rem;
        text-align: center;
    }
}